import * as React from "react"
import GetTheKit from './ui/GetTheKit'
import ScheduleAMeeting from './ui/ScheduleAMeeting'

const LearnMore = (props) => {
	return (
		<>
			<h3 className="small">Learn more about UVM Health Advantage Medicare plans:</h3>

			<div className="ctas secondary">
				<div className="flexwrap">
					{(props.showonly != 'meeting') &&
						<GetTheKit />
					}
					{(props.showonly != 'kit') &&
						<ScheduleAMeeting />
					}
				</div>
			</div>
		</>
	)
}

export default LearnMore;
