import * as React from "react"
import { Link } from 'gatsby'
import calendarsecondaryicn from '../../assets/img/calendar-secondary-icn.svg'

const ScheduleAMeeting = () => {
	return (
		<div className="secondary-cta">
			<img src={calendarsecondaryicn} alt="" width="60" height="60" className="icon" />
			<p className="head"><b>Meet With Us!</b></p>
			<p>Our UVM Health Advantage Plan Guides will meet with you on your terms &ndash; in person, by phone or videoconference.</p>
			<Link to="/meet-with-us/" className="button secondary">Schedule a Meeting</Link>
		</div>
	)
}

export default ScheduleAMeeting;
