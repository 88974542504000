import * as React from "react"
import { useEffect } from 'react';
import Layout from "../templates/layout-standard"
import Seo from "../components/seo"
import LearnMore from '../components/learnmore';
import SideBar from '../components/sidebar';
import SpeakWithAdvisor from '../components/ui/SpeakWithAdvisor';

const AlreadyAMember = ({ location }) => {

	useEffect(() => {
		document.querySelectorAll(".accordiontrigger").forEach(trigger => {
			let accordionContainer = trigger.nextElementSibling;
		
			trigger.addEventListener('click', event => {
				trigger.classList.toggle("open");
				if (trigger.classList.contains('open')) {
					trigger.setAttribute("aria-hidden", "false");
				} else {
					trigger.setAttribute("aria-hidden", "true");
				}
			
				if (!accordionContainer.classList.contains('open')) {
					accordionContainer.classList.add('open');
					accordionContainer.style.height = 'auto';
				
					let height = accordionContainer.clientHeight + 'px';
				
					accordionContainer.style.height = '0px';
				
					setTimeout(function () {
						accordionContainer.style.height = height;
					}, 0);
				} else {
					accordionContainer.style.height = '0px';		
					accordionContainer.addEventListener('transitionend', function () {
						accordionContainer.classList.remove('open');
					}, {
						once: true
					});
				}
		
			});
		});
	});

	return (
		<Layout location={location} heading1="Already a Member?" heading2="">
			<Seo title="Already a Member?" meta={[{description: 'As a UVM Health Advantage member, your health plan and your providers work together to support you on your health journey.'}]} bodyclass="alreadymvpmember subpage" />
			
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">
					
						<main>
							<h2 className="like-h3">Welcome to UVM Health Advantage.</h2>
							<p>Thank you for choosing a UVM Health Advantage plan. As a UVM Health Advantage member, your health plan and your providers work together to support you on your health journey.</p>

							<p>UVM Health Advantage plans are insured and administered by MVP Health Care<sup>&reg;</sup> (MVP). MVP will  manage your membership and support you in accessing your benefits. You&rsquo;ll receive all of your benefit information and claims from MVP.</p>

							<p><a href="https://www.mvphealthcare.com/members/sign-in" className="external">Create Your Online Account</a> or <a href="https://my.mvphealthcare.com/" class="external">Sign In</a> to your Member account at the MVP website. You&rsquo;ll have convenient access to plan details like your claims history, deductibles, and limits. You&rsquo;ll also be able to pay your monthly premium bill, get reminders of important preventive care visits, order new MVP Member ID cards, and more.</p>

							<p><a href="https://www.mvphealthcare.com/members/sign-in" className="external">Learn more about your plan benefits</a>, including Care Guide support, co-pays, prescription drug coverage, well-being benefits and more.</p>

							<p>If you have any questions, the MVP Medicare Customer Care Center team is here to help.</p>

							<p><b>Call </b><span className="phone_desktop"><b>1-800-665-7924</b></span><a className="phone_mobile" href="tel:18006657924"><b>1-800-665-7924</b></a> (TTY 711) October 1-March 31, seven days a week, 8&nbsp;a.m.-8&nbsp;p.m. Eastern Time. April 1-September 30, Monday-Friday, 8&nbsp;a.m.-8&nbsp;p.m.</p>
							{/* <p><strong>Call 1-800-665-7924</strong> (TTY 711) seven days a week, 8 a.m.-8 p.m., Eastern Time. From April 1-September 30, call Monday-Friday, 8 a.m.-8 p.m.</p> */}

							<h3 className="like-h4">Here are answers to a few common questions you may have.</h3>
							<div className="accordion">
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-1" className="accordiontrigger" aria-controls="accordion-content-1" aria-hidden="true">
										I don&rsquo;t see a UVM Health Network provider &ndash; what does this mean for me? 
									</button>
									<div id="accordion-content-1" className="accordion-content" aria-labelledby="accordion-trigger-1">
										<div className="contentwrap">
											<p>UVM Health Advantage members have access to MVP&rsquo;s full regional network of 54,000 doctors and hospitals across New York State, Vermont, and in additional areas, which includes UVM Health Network providers. You can see the providers you choose in your community. Plan co-pays and cost shares apply.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-2" class="accordiontrigger" aria-controls="accordion-content-2" aria-hidden="true">
										Am I limited to only seeing UVM providers?
									</button>
									<div id="accordion-content-2" className="accordion-content" aria-labelledby="accordion-trigger-2">
										<div className="contentwrap">
											<p>Plan members have access  to the full MVP regional network of more than 54,000 doctors and hospitals across New York State, Vermont, and in additional areas. You can choose from MVP&rsquo;s extensive network of providers and hospitals &ndash; including UVM Health Network &ndash; with options for virtual care, worldwide emergency care and non-emergency or routine care from any Medicare-participating provider nationwide. So you can get the care when and where you need it.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-3" className="accordiontrigger" aria-controls="accordion-content-3" aria-hidden="true">
										I live in New York, why am I enrolled in a Vermont plan?
									</button>
									<div id="accordion-content-3" className="accordion-content" aria-labelledby="accordion-trigger-3">
										<div className="contentwrap">
											<p>UVM Health Advantage was inspired by what people across Vermont and northern New York told us they want in a Medicare plan. UVM Health Advantage plans are available in: New York &ndash;Clinton, Essex, Franklin, Hamilton and St. Lawrence; Vermont &ndash; Addison, Bennington, Caledonia, Chittenden, Essex, Franklin, Grand Isle, Lamoille, Orange, Orleans, Rutland, Washington, Windham and Windsor.</p>
										</div>
									</div>
								</div>
								{/* <div class="accordion-item">
									<button type="button" id="accordion-trigger-4" class="accordiontrigger" aria-controls="accordion-content-4" aria-hidden="true">
										How do I switch to a UVM Health Advantage plan?
									</button>
									<div id="accordion-content-4" class="accordion-content" aria-labelledby="accordion-trigger-4">
										<div class="contentwrap">
										<p>If you would like to join a UVM Health Advantage plan, we can help to enroll you. For current members, please call the phone number on the back of your member ID card and a Customer Care representative can assist you in switching plans. For those new to MVP, please call a UVM Health Advantage Plan Guide for assistance. Call 1-833-368-4592 (TTY 711) seven days a week, 8 a.m. &ndash; 8p.m. From April 1&ndash;September 30, Monday&ndash;Friday, 8 a.m.&ndash;8 p.m.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-5" class="accordiontrigger" aria-controls="accordion-content-5" aria-hidden="true">
										Will I receive a new health plan ID card?
									</button>
									<div id="accordion-content-5" class="accordion-content" aria-labelledby="accordion-trigger-5">
										<div class="contentwrap">
										<p>Any time a current MVP Medicare member changes health plans, they are sent a new Member ID card. Approved UVM Health Advantage plans are insured and administered by MVP Health Care, so your Member ID card for your UVM Health Advantage plan will still have the MVP logo and accompanying plan information materials.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-6" class="accordiontrigger" aria-controls="accordion-content-6" aria-hidden="true">
										Where is UVM Health Advantage available?
									</button>
									<div id="accordion-content-6" class="accordion-content" aria-labelledby="accordion-trigger-6">
										<div class="contentwrap">
										<p>UVM Health Advantage is available across all of Vermont and in Clinton, Essex, Franklin, Hamilton, and St. Lawrence counties in New York. Eligible Medicare consumers will be able to enroll during the fall Medicare Annual Enrollment Period&mdash;starting October 15, 2021&mdash;for coverage beginning January 1, 2022.</p>
										</div>
									</div>
								</div> */}
							</div>

						</main>
						
						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default AlreadyAMember
