import * as React from "react"
import { Link } from 'gatsby';
import cursorsecondaryicn from '../../assets/img/cursor-secondary-icn.svg'

const GetTheKit = () => {
	return (
		<div className="secondary-cta">
			<img src={cursorsecondaryicn} alt="" width="60" height="60" className="icon" />
			<p className="head"><b>Request a UVM Health Advantage Plan Benefit Kit!</b></p>
			<p>Get benefit details, compare plans and more when you request your FREE Plan Benefit kit now.</p>
			<Link to="/request-a-kit/" className="button secondary">Get the Kit</Link>
		</div>
	)
}

export default GetTheKit;
