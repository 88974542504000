import * as React from "react"
import medadvisorprimaryicn from '../../assets/img/med-advisor-primary-icn.svg';

const SpeakWithAdvisor = () => {
	return (
		<div className="ctas tertiary">
			<div className="tertiary-cta">
				<img src={medadvisorprimaryicn} alt="UVM Health Advantage Plan Guides" width="97" height="97" />
				<div className="content">
					<p className="head"><b>UVM Health Advantage Plan Guides are here to help.</b></p>
					<p>Get expert guidance to help you understand your options, ﬁnd the right plan and make sure your transition to your new plan goes smoothly with no disruption to your care.</p>
					<p className="subhead">
						<span className="phone_desktop"><b>1-833-368-4592</b></span>
						<a className="phone_mobile" href="tel:18333684592"><b>1-833-368-4592</b></a>
						<span> (TTY 711)</span>
					</p>
					<p>October 1-March 31, seven days a week, 8&nbsp;a.m.-8&nbsp;p.m. Eastern Time.<br />
					April 1-September 30, Monday-Friday, 8&nbsp;a.m.-8&nbsp;p.m.</p>
					{/* <p>Seven days a week, 8&nbsp;a.m.-8&nbsp;p.m. Eastern Time<br />
					April 1-September 30, Monday-Friday, 8&nbsp;a.m.-8&nbsp;p.m. </p> */}
				</div>
			</div>
		</div>
	)
}

export default SpeakWithAdvisor;
